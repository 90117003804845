.talk-page{
  margin: 60px auto 0px 0px;
  max-width: 1200px;
  background-color: white;
}

.talk-image {
text-align: center;
background-repeat: no-repeat;
background-size: cover;
width: 100%;
border: solid 1px #ccc;
overflow: hidden;
z-index: 2;
position: relative;
background-color: black;
z-index: 0;
margin-bottom: 0px;
padding: 0px;
object-fit: contain;
width: 100%;
z-index: 2;
padding: 0px;
}

.talk-specs-box {
padding-top: 10px;
padding-left: 10px;
}


@media only screen and (max-width: 880px) and (min-width: 600px) {
.talk-specs-container {
  grid-template-columns: 1fr 1fr;
}
}

@media only screen and (max-width: 600px) {
.talk-specs-container {
  grid-template-columns: 1fr;
}
}

.talk-specs-container div {
border: solid 1px #ddd;
background-color: #ffffff;
padding: 10px;
}

.talk-specs-container h3 {
text-align: center;
padding: 0px;
margin: 0px;
margin-top: 10px;
}

.talk-specs-container ul {
list-style-type: none;
margin: 0px;
padding: 0px;
margin-top: 10px;
}

.talk-specs-container ul li {
display: block;
padding: 4px;
}

.tech-specs-container ul li:nth-child(odd) {
background-color: #f3f3f3;
}

.button-container {
  text-align: center;
  padding: 10px 10px 10px 10px;;
}

.button {
display: inline-block;
margin: 0px 10px 10px 10px;
padding: 10px 10px 30px 10px;
text-align: center;
text-decoration: none;
font-size: 16px;
width: 140px;
color: white;
border-radius: 6px;
text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
background: rgb(223, 117, 20);
cursor: pointer;;
}

.button:hover {
background: rgb(203, 97, 10);
}


.talk-page section {
padding-top: 20px;
padding-bottom: 20px;
}

.talk-page section:nth-child(odd) {
background-color: #f5f5f5;
}

.talk-page section h2 {
text-align: center;
font-weight: normal;
font-size: 1.8em;
margin-top: 20px;
margin-bottom: 15px;
}

.talk-page-writing {
text-align: justify;
}

.talk-page-section hr {
width: 100px;
color: #ffb800;
background-color: #ffb800;
height: 2px;
border: 0px;
margin-bottom: 20px;
}
