.skills-component {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

@media only screen and (max-width: 880px) and (min-width: 600px) {
  .skills-component {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 600px) {
  .skills-component {
    grid-template-columns: 1fr;
  }
}

.skills-component div {
  border: solid 1px #ddd;
  background-color: #ffffff;
  padding: 10px;
}

.skills-component h3 {
  text-align: center;
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
}

.skills-component ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  margin-top: 10px;
}

.skills-component ul li {
  display: block;
  padding: 4px;
}

.skills-component ul li:nth-child(odd) {
  background-color: #f3f3f3;
}
