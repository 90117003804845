.talks-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 18px;
  align-items: stretch;
  text-decoration: none;
  padding-top: 10px;
}

@media only screen and (max-width: 880px) and (min-width: 600px) {
  body .talks-container {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 600px) {
  body .talks-container {
    grid-template-columns: 100%;
  }
}

.TechPageContainer {
  height: 100%;
  border: 1px solid #ccc;
  background-color: #f3f3f3;
}

.project-item {
  margin-left: 5px;
}

.project-image {
  width: 100%;
  overflow: hidden;
}

.project-image img {
  width: 100%;
  object-fit: contain;
  background-color: white;
  padding-bottom: 8px;
}

.project-summary {
  padding: 8px;
  padding-left: 14px;
  padding-right: 14px;

}

.TechPageContainer section {
  padding-top: 20px;
  padding-bottom: 20px;
}

section.my-projects {
  background-color: white;
}

.TechPageContainer section h2 {
  text-align: center;
  font-weight: normal;
  font-size: 1.8em;
  margin-top: 20px;
  margin-bottom: 15px;
}

.TechPageContainer section hr {
  width: 100px;
  color: #ffb800;
  background-color: #ffb800;
  height: 2px;
  border: 0px;
  margin-bottom: 20px;
}

.TechPageContainer a {
  text-decoration: none;
  text-emphasis: 1em;
}


.button-container-tech {
    text-align: center;
    padding: 10px 0px 10px 0px;
    margin-top: 20px;
}

.button-tech {
  display: inline-block;
  margin: 0px 10px 10px 10px;
  padding: 10px 10px 10px 10px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  width: 140px;
  color: white;
  border-radius: 6px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  background: rgb(223, 117, 20);
  cursor: pointer;
}

.button-tech:hover {
  background: rgb(203, 97, 10);
}
