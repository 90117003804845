.landing-hero-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.0)), url("../../../public/hero-01.jpg");
  height: 460px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.landing-hero-image h1 {
  position: absolute;
  top: 160px;
  right: 60px;
  color: white;
  text-shadow: 1px 1px 0px black;
  font-weight: normal;
  font-size: 2.2em;
  line-height: 1.0;
}

.landing-hero-image .tagline {
  position: absolute;
  top: 260px;
  right: 60px;
  width: 360px;
  text-align: right;
  line-height: 1.5em;
  color: white;
  font-size: 1.1em;
  font-weight: bold;
  text-shadow: 1px 1px 0px black;
}

@media screen and (max-width: 640px) {
  body .landing-hero-image h1 {
    width: 100%;
    right: 0px;
    top: 220px;
    text-align: center;
    font-size: 2.0em;
    line-height: 1.0;
  }

  body .landing-hero-image .tagline {
    width: 84%;
    right: 8%;
    top: 320px;
    text-align: center;
    font-size: 1.0em;
  }
}

.landing-page section {
  padding-top: 20px;
  padding-bottom: 20px;
}

.landing-page section:nth-child(odd) {
  background-color: #f5f5f5;
}

.landing-page section h2 {
  text-align: center;
  font-weight: normal;
  font-size: 1.8em;
  margin-top: 20px;
  margin-bottom: 15px;
}

.landing-page section hr {
  width: 100px;
  color: #ffb800;
  background-color: #ffb800;
  height: 2px;
  border: 0px;
  margin-bottom: 20px;
}

.landing-page a {
  text-decoration: none;
  color: black;
  text-emphasis: 1em;
}
