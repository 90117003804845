.project-container {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-column-gap: 20px;
  grid-row-gap: 24px;
  align-items: stretch;
  text-decoration: none;
  padding-top: 10px;
}

@media only screen and (max-width: 880px) and (min-width: 600px) {
  body .project-container {
    grid-template-columns: 48% 48%;
  }
}

@media only screen and (max-width: 600px) {
  body .project-container {
    grid-template-columns: 100%;
  }
}
