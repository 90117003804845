.page-header {
  position:fixed;
  top:0;
  width:100%;
  margin: 0px auto;
  max-width: 1200px;
  height: 60px;
  z-index:100;
  background-color: rgba(0, 0, 0, 0.9);
  color: #eee;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  display: grid;
  grid-template-columns: auto auto 100px;
}

header .logo {
  margin: 10px;
  margin-left: 12px;
}

.page-title {
  font-weight: bold;
}

.page-subtitle {
  font-size: 0.9em;
  font-style: italic;
}

@media screen and (max-width: 1000px) {
  body .page-header {
    grid-template-columns: auto auto;
  }

  body header .logo {
    display: none;
  }

  body header .navigation {
    width: 100%;
    justify-self: start;
  }

  body header .social {
    width: 100%;
    justify-self: end;
    text-align: right;
    padding-right: 10px;
  }

  body header .social ul {
    border-left: none;
  }
}

@media screen and (max-width: 400px) {
  body header nav ul li {
    padding-left: 8px;
    padding-right: 8px;
  }
}

header nav {

}

header nav ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

header nav ul li {
  display: inline-block;
  padding-left: 14px;
  padding-right: 14px;
}

header nav a {
  display: inline-block;
  color: white;
  text-decoration: none;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: .25s;
  font-family: 'Roboto', sans-serif;
}

header nav a:hover {
  color: #aaa;
}

header .navigation {
  justify-self: end;
  padding-right: 10px;
}

header .social {

}

header .social ul {
  height: 30px;
  border-left: solid 1px white;
  display: inline-block;
  padding-left: 10px;
}

header .social ul li {
  display: inline-block;
  padding-left: 6px;
  padding-right: 6px;
}

header .social a {
  transition: 0.25s;
}

header .social a:hover {
  opacity: 0.6;
}

header .social ul li img {
  width: 28px;
  height: 28px;
}
