@import url("https://use.typekit.net/bam4lwa.css");


.hero {
  width: 100%;
  /* background-color: #b8008b; */
  margin-left: auto;
  margin-right: auto;
}

.hero-image {
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  overflow: hidden;
  height: 500px;
  z-index: 2;
  position: relative;
  background-color: black;
  z-index: 0;
  margin-bottom: 0px;
  padding: 0px;
}

.hero-image img {
  height: auto;
  width: 100%;
opacity: 0.6;
z-index: 2;
padding: 0px;

}

.hero-text {
  /* padding-left: 96px; */
  top: 200px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  font-family: "ff-dagny-web-pro", sans-serif;
  font-size: 50px;
  color: #ffffff;
  font-size: 50px;
  text-align: center;
  padding-left: 80px;
  padding-right: 80px;
  width: 100%;
}


.hero-text h1 {
  font-family: "ff-dagny-web-pro", sans-serif;
  color: #ffffff;
  font-size: 50px;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-left: 80px;
  padding-right: 80px;
}

.hero-text p {
  font-family: "ff-dagny-web-pro", sans-serif;
  /* color: #8fbab5; */
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-left: 80px;
  padding-right: 80px;
}
