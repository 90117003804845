
.project-preview {
  width: 100%;
  height: 300px;
  overflow: hidden;
  box-sizing: border-box;
  color: black;
  font-family: 'Roboto';
  border-radius: 2px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 5px -3px;
  transition-duration: 0.3s;
}

.project-preview:hover {
  margin-top: -12px;
  transition-duration: 0.2s;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 5px -2px;
  cursor: pointer;
}

.project-preview-top {
  width: 100%;
  height: 170px;
  padding-top: 30px;
  background-color: rgba(243, 245, 248, 0.7);
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  color: #555877;
}

.project-preview-top img {
  width: 64px;
}

.project-preview-bottom {
  padding: 12px;
  line-height: 1.5;
}