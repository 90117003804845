.button {

      background-color: #92a39e;
      border: 1px black;
      text-align: center;
      font-size: 14px;
      margin-left: 7px;
      margin-right: 7px;
      height: 30px;
      border-radius: 20px;
      font-weight: bold;

}
