
#name {
  font-size: 16px;
  font-weight: bold;
  margin: 0;

}

#email {

  font-size: 14px;
  margin-top: 4px;
  font-style: italic;
  color: #888;
}

#message {

  font-size: 14px;
  margin-top: 4px;
  width: 300px;

}

.title {
  font-size: 24px;
  padding: 0 24px;
}


.ContactItem h2 {
  text-align: center;
  font-weight: normal;
  font-size: 1.8em;
  margin-top: 20px;
  margin-bottom: 15px;
}


.ContactItem hr {
  width: 100px;
  color: #ffb800;
  background-color: #ffb800;
  height: 2px;
  border: 0px;
  margin-bottom: 20px;
}

.ContactItem p {
  padding-left: 10%;
  padding-right: 10%;
}


.ContactItem {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
  text-align: center;
  background-repeat: no-repeat;
  width: 100%;
  overflow: hidden;
  height: 500px;
  position: relative;
  padding: 0px;
}

a {
text-decoration: none
}
