@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css?family=Roboto');

body {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.4em;
  color: #555555;
  background-color: #eeeeee;
}

.page-container {
  margin: 60px auto 0px;
  max-width: 1200px;
  background-color: white;
}

.content-padding {
  padding-left: 5%;
  padding-right: 5%;
}

h1 {
  padding-top: 24px;
  padding-bottom: 4px;
  font-weight: normal;
  line-height: 1.2;
}